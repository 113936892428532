<template>
  <div class="common-container cad-container cad-main-container">
    <div class="flex-center p24 home-top-wrapper">
      <div class="flex top-wrapper">
        <div class="flex-center logo" @click.stop="$router.replace('/staging')">
          <img src="~images/home/logo.png" alt="" />
          <div class="cad-title">{{ productName }}</div>
        </div>
      </div>
      <div class="flex top-right-wrapper">
        <cadItem
          v-if="isOnShowEdit"
          @addDesign="addDesign"
          :shareUrl="shareUrl"
        ></cadItem>
        <!-- <userInfo></userInfo> -->
        <div
          class="flex-center back-svg"
          @click.stop="$router.replace('/staging')"
        >
          <svg
            t="1692286294018"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="3095"
            width="28"
            height="28"
          >
            <path
              d="M462.266 167.466l-328.098 284.381 328.098 284.359v-191.379s454.799-147.683 362.705 311.707c0 0 293.586-557.828-368.422-519.544l5.719-169.527z"
              fill="#fff"
              p-id="3096"
            ></path>
          </svg>
        </div>
        <userInfo :isMsg="false"></userInfo>
      </div>
    </div>
    <div class="flex p24 cad-btn-box">
      <!-- 类型选择栏 -->
      <div class="flex cad-typ-box">
        <!-- <div class="flex-center cad-type-item" @click="fullmap">
          <svg-icon icon-class="scroll" />
        </div> -->
        <div
          class="flex-center cad-type-item radius-box"
          :class="{ 'cad-type-active': type.isSelected }"
          v-for="(type, idx) in typeList"
          :key="idx"
          @click="selectCadType(idx)"
        >
          <template v-if="type.name !== '测量'">
            <svg-icon :icon-class="type.typeName" />
            <span class="type-name">{{ type.name }}</span>
          </template>
          <template v-else>
            <el-dropdown @command="handleMeasure">
              <span class="el-dropdown-link">
                <svg-icon :icon-class="type.typeName" />
                <span class="type-name">{{ type.name }}</span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(ele, idx) in measureList"
                  :key="idx"
                  :command="ele.type"
                  >{{ ele.name }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </div>
      </div>
      <div class="flex back-box">
        <div class="flex cad-search-box">
          <el-button
            class="radius-box"
            type="primary"
            v-if="isOnShowEdit"
            @click="undo"
            :disabled="mapLoading"
            >撤销</el-button
          >
          <el-button
            class="radius-box"
            type="primary"
            v-if="isOnShowEdit"
            @click="redo"
            :disabled="mapLoading"
            >重做</el-button
          >

          <el-button
            class="radius-box"
            type="primary"
            v-if="isOnShowEdit"
            @click="regen()"
            :disabled="mapLoading"
            >重绘</el-button
          >

          <el-button
            type="primary"
            class="radius-box"
            value="fullmap"
            @click="fullmap"
            :disabled="mapLoading"
            >居中</el-button
          >
          <el-button
            type="primary"
            class="radius-box"
            @click="save(true)"
            :disabled="mapLoading"
            v-if="isOnShowEdit"
            >保存</el-button
          >
        </div>
      </div>
    </div>
    <div class="mt30 columm-center cad-wraapper" @click="cancelColor">
      <div class="cad-main">
        <!-- v-if="isOnShowEdit && isEditCad" v-loading="mapLoading"
        element-loading-text="图层加载中"
        element-loading-spinner="el-icon-loading" -->
        <template>
          <!-- 编辑工具栏 -->
          <div class="scroll-Y1 cad-edit-box" v-if="isShowEdit">
            <div
              class="flex-center cad-edit-item"
              v-for="(edit, idx) in editList"
              :key="idx"
              @click="drawPix(edit)"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="edit.name"
                placement="right"
              >
                <div class="flex svg-box">
                  <svg-icon
                    :icon-class="edit.typeName"
                    color="#D0D0D1"
                    width="18"
                    height="18"
                  />
                </div>
              </el-tooltip>
            </div>
          </div>
          <div
            class="cad-tool-box"
            :class="{ 'cad-tool-wrapper': cardList.length > 0 }"
          >
            <div
              class="flex-center cad-edit-item"
              v-for="(edit, idx) in navigationList"
              :key="idx"
              @click="drawPixTool(edit, idx)"
            >
              <div
                class="flex svg-box"
                v-if="edit.list && edit.list.length > 0"
              >
                <svg-icon
                  :icon-class="edit.typeName"
                  color="#D0D0D1"
                  width="18"
                  height="18"
                />
                <div class="cad-edit-list-box" v-if="navigationIndex == idx">
                  <div class="radius-box cad-edit-item-box">
                    <div class="cad-edit-list-item">
                      {{ edit.name }}
                    </div>
                    <div
                      class="cad-edit-list-item cad-edit-list-active"
                      v-for="(it, index) in edit.list"
                      :key="index"
                      @click="drawPix(it, true)"
                    >
                      <span v-if="it.name.length <= 8">{{ it.name }}</span>
                      <el-tooltip
                        v-else
                        class="item"
                        effect="dark"
                        :content="it.name"
                        placement="top"
                      >
                        <span>{{ it.name }}</span>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <el-tooltip
                v-else
                class="item"
                effect="dark"
                :content="edit.name"
                placement="left"
              >
                <div class="flex svg-box">
                  <svg-icon
                    :icon-class="edit.typeName"
                    color="#D0D0D1"
                    width="18"
                    height="18"
                  />
                </div>
              </el-tooltip>
            </div>
          </div>
          <!-- 图层、属性栏 -->
          <div class="box-card" v-if="cardList.length > 0">
            <div class="box-card-top">
              <div
                class="card-top-item"
                v-for="(card, idx) in cardList"
                :key="idx"
                :class="{ 'card-top-active': isDrawName == card.name }"
                @click="selectCardType(card.name, idx)"
              >
                {{ card.name }}
                <img
                  src="~images/icon_close.png"
                  class="card-del-icon"
                  alt=""
                  @click.stop="delCard(idx)"
                />
              </div>
            </div>
            <div
              class="flex box-card-type"
              v-if="!isShowLayer && selectGeometrys.length > 0"
            >
              <div class="flex-between sure-attr">
                <template>
                  <div class="sure-attr-label">类型选择：</div>
                  <div class="sure-attr-select">
                    <el-select
                      v-model="geometryVal"
                      placeholder="请选择"
                      v-if="selectGeometrys.length > 0"
                      @change="selectGeoArr"
                      style="flex: 1"
                      :disabled="selectGeometrys.length == 1"
                    >
                      <el-option
                        v-for="(item, idx) in selectGeometrys"
                        :key="idx"
                        :label="item.name"
                        :value="item.sname"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </template>
              </div>
            </div>
            <div
              class="scroll-Y box-card-content"
              v-if="cardDatas.length > 0"
              :class="{
                p12: !isShowLayer,
                'box-card-wrapper': layerIndexs.length > 1
              }"
              @click.stop="isDelLayer = false"
            >
              <template v-if="isShowLayer">
                <div class="layer-top-box">
                  <div class="flex layer-add-box">
                    <el-button
                      class="radius-box"
                      @click.stop="selectAllLayer"
                      type="success"
                      size="small"
                      >全选</el-button
                    >
                    <el-button
                      @click.stop="invertLayer"
                      size="small"
                      type="info"
                      class="radius-box"
                      >反选</el-button
                    >
                    <el-button
                      @click.stop="addLayer"
                      type="info"
                      size="small"
                      class="radius-box"
                      >新增图层</el-button
                    >
                  </div>
                  <div class="scroll-Y layer-top-wrapper">
                    <div
                      class="flex card-content-item"
                      v-for="(ele, index) in cardDatas"
                      :key="index"
                      @click.stop="isDelLayer = false"
                      @click.right.stop="event => showLayerRight(event, index)"
                      :class="{
                        'card-content-item-active':
                          layerIndexs.indexOf(index) > -1
                      }"
                    >
                      <div
                        class="color-box"
                        @click.stop="
                          onShowTypeColor(
                            ele.colorIndex,
                            ele.selectColor,
                            index,
                            'tc'
                          )
                        "
                        :style="{ background: judegBylayer(ele.selectColor) }"
                      ></div>
                      <div class="flex prop-box">
                        <div
                          class="text_hidden propname"
                          style="width: 200px"
                          @click.stop="editLayer(index)"
                        >
                          {{ ele._name }}
                        </div>
                        <div class="flex propvalue tc-box">
                          <el-switch
                            v-model="ele.isChecked"
                            active-color="#3478f7"
                            inactive-color="#ccc"
                            @change="val => changeAttr(val, index)"
                          >
                          </el-switch>

                          <svg
                            t="1692096932204"
                            class="icon"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="3419"
                            width="20"
                            height="20"
                            v-if="!ele.isLock"
                            :class="{ 'svg-ative': ele.isLock }"
                            @click.stop="changeLock(ele, index)"
                          >
                            <path
                              d="M513.885091 93.090909c18.757818 0 33.978182 15.197091 33.978182 33.978182v35.770182c165.818182 16.802909 297.541818 149.108364 313.483636 315.205818h35.607273c18.757818 0 33.954909 15.197091 33.954909 33.954909v1.885091a33.978182 33.978182 0 0 1-33.978182 33.978182h-35.770182c-16.756364 165.189818-148.107636 296.541091-313.320727 313.297454v35.793455A33.978182 33.978182 0 0 1 513.908364 930.909091H512a33.978182 33.978182 0 0 1-33.978182-33.978182v-35.584c-166.074182-15.941818-298.356364-147.665455-315.182545-313.483636H127.045818A33.978182 33.978182 0 0 1 93.090909 513.861818V512c0-18.757818 15.197091-33.978182 33.978182-33.978182h35.584C178.664727 311.342545 311.319273 178.688 478.021818 162.676364v-35.607273C478.021818 108.288 493.242182 93.090909 512 93.090909h1.885091zM232.866909 478.021818h30.045091c18.757818 0 33.978182 15.220364 33.978182 33.978182v1.885091a33.978182 33.978182 0 0 1-33.978182 33.978182h-29.789091A281.297455 281.297455 0 0 0 478.021818 791.133091v-30.045091c0-18.757818 15.220364-33.978182 33.978182-33.978182h1.885091c18.757818 0 33.978182 15.220364 33.978182 33.978182v29.789091a281.297455 281.297455 0 0 0 243.037091-243.013818h-29.812364a33.978182 33.978182 0 0 1-33.978182-33.978182V512c0-18.757818 15.220364-33.978182 33.978182-33.978182h30.021818a281.297455 281.297455 0 0 0-243.246545-244.922182v29.812364a33.978182 33.978182 0 0 1-33.978182 33.978182H512a33.978182 33.978182 0 0 1-33.978182-33.978182v-30.021818A281.297455 281.297455 0 0 0 232.866909 478.021818zM512 410.112a101.888 101.888 0 1 1 0 203.776 101.888 101.888 0 0 1 0-203.776z"
                              p-id="3420"
                            ></path>
                          </svg>
                          <svg
                            v-else
                            :class="{ 'svg-ative': ele.isLock }"
                            @click.stop="changeLock(ele, index)"
                            t="1693393583590"
                            class="icon"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="2283"
                            width="20"
                            height="20"
                          >
                            <path
                              d="M63.222927 512c0 0 231.767598 189.584869 254.790964 350.823134 0 0 303.906591-497.491565 641.581264-542.003338 0 0-102.837156-74.943876-69.070098-193.395662 0 0-187.255825 18.684548-540.279067 566.637388L184.79375 413.212066 63.222927 512z"
                              p-id="2284"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div
                        class="card-right-box"
                        v-if="
                          delLaerData &&
                            delLaerData._name == ele._name &&
                            isDelLayer
                        "
                        :style="{ top: layerY + 'px' }"
                      >
                        <!-- <div
                          class="card-right-item"
                          @click.stop="editLayer(index)"
                        >
                          修改图层
                        </div> -->
                        <div
                          class="card-right-item"
                          @click.stop="delLayer(index)"
                        >
                          删除图层
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="layer-botom-box" v-show="layerIndexs.length <= 1">
                  <div class="layer-botom-title">图层特性</div>
                  <div class="layer-bottom-content">
                    <div class="flex card-content-item attr-box">
                      <div class="flex prop-box">
                        <div class="text_hidden propname">名称</div>

                        <div class="flex propvalue">
                          <div
                            class="input-item-box"
                            @dblclick="onShowInputs(selectLayer)"
                            :class="{ p15: !selectLayer.showInput }"
                          >
                            <el-input
                              v-show="selectLayer.showInput"
                              v-model="selectLayer._name"
                              ref="input"
                              placeholder="请输入名称"
                              @keyup.enter.native="
                                setLayerAtt('_name', selectLayer)
                              "
                              @blur="() => setLayerAtt('_name', selectLayer)"
                            ></el-input>

                            <template v-if="!selectLayer.showInput">
                              {{ selectLayer._name }}
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex card-content-item attr-box">
                      <div class="flex prop-box">
                        <div class="text_hidden propname">线型</div>

                        <div class="flex propvalue">
                          <el-select
                            v-model="selectLayer._linetype"
                            placeholder="请选择"
                            @change="
                              () => setLayerAtt('_linetype', selectLayer)
                            "
                          >
                            <el-option
                              v-for="(item, idx) in lineList"
                              :key="idx"
                              :label="item"
                              :value="item"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                    <div class="flex card-content-item attr-box">
                      <div class="flex prop-box">
                        <div class="text_hidden propname">线宽</div>

                        <div class="flex propvalue">
                          <el-select
                            v-model="selectLayer._linewidth"
                            placeholder="请选择"
                            @change="
                              () => setLayerAtt('_linewidth', selectLayer)
                            "
                          >
                            <el-option
                              v-for="(item, idx) in lineWidthList"
                              :key="idx"
                              :label="item"
                              :value="item"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                    <div class="flex layer-bottom-item">
                      <div class="layer-botom-label">设为当前</div>
                      <div class="flex layer-bottom-value">
                        <el-switch
                          v-model="selectLayer.isLock"
                          @change="() => setLayerAtt('isChecked', selectLayer)"
                        >
                        </el-switch>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div @click="cancelColor">
                  <div
                    class="geometry-box"
                    :class="{ 'hiden-geometry': showAttr }"
                  >
                    <div class="geometry-type">
                      常规
                      <i
                        @click="changeShow('showAttr')"
                        :class="{
                          'el-icon-minus': !showAttr,
                          'el-icon-plus': showAttr
                        }"
                      ></i>
                    </div>
                    <div
                      class="flex card-content-item attr-box"
                      v-for="(ele, index) in attributeList"
                      :key="index"
                    >
                      <div class="flex prop-box">
                        <div class="text_hidden propname">{{ ele.name }}</div>

                        <div class="flex propvalue">
                          <el-select
                            v-if="ele.type == 'select'"
                            v-model="ele.value"
                            placeholder="请选择"
                            :disabled="ele.disable"
                            @change="() => setConventionData(ele, false)"
                          >
                            <el-option
                              v-for="(item, idx) in ele.list"
                              :key="idx"
                              :label="item"
                              :value="item"
                            >
                            </el-option>
                          </el-select>
                          <div
                            class="input-item-box"
                            v-else-if="ele.type == 'input'"
                            @dblclick="onShowInput(ele)"
                            :class="{ p15: !ele.showInput }"
                          >
                            <el-input
                              v-if="ele.showInput"
                              v-model="ele.value"
                              ref="input"
                              placeholder="请输入内容"
                              :disabled="ele.disable"
                              @input="
                                ele.value = checkGreaterZero(
                                  ele.value,
                                  ele.typeName
                                )
                              "
                              @keyup.enter.native="setCommValue(ele)"
                              @blur="() => setCommValue(ele)"
                            ></el-input>
                            <template v-else>
                              {{ ele.value }}
                            </template>
                          </div>
                          <selectColor
                            v-else-if="ele.type == 'color'"
                            v-model="ele.index"
                            :color="ele.value"
                            @callBackColor="val => callBackCgColor(val, ele)"
                          ></selectColor>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="geometry-box"
                    :class="{ 'hiden-geometry': showGeometry }"
                    v-if="geometryArrIndex > 0"
                  >
                    <div class="geometry-type">
                      几何图形-{{ geometryArr[geometryArrIndex].sType }}
                      <i
                        @click="changeShow('showGeometry')"
                        :class="{
                          'el-icon-minus': !showGeometry,
                          'el-icon-plus': showGeometry
                        }"
                      ></i>
                    </div>

                    <div v-if="geometryArr[geometryArrIndex].sType == '直线'">
                      <straightLine
                        :pixiMap="pixiMap"
                        :featureDatas="lineDatas"
                        @entryInput="entryInput"
                      ></straightLine>
                    </div>
                    <div
                      v-else-if="
                        geometryArr[geometryArrIndex].sType == '多义线'
                      "
                    >
                      <dyxLine
                        :pixiMap="pixiMap"
                        :featureDatas="dyxLineDatas"
                        :fn="editAttr"
                      ></dyxLine>
                    </div>
                    <div
                      v-else-if="geometryArr[geometryArrIndex].sType == '块'"
                    >
                      <elBlock
                        :pixiMap="pixiMap"
                        :featureDatas="blockDatas"
                        @entryInput="entryInput"
                      ></elBlock>
                    </div>
                    <div
                      v-else-if="geometryArr[geometryArrIndex].sType == '圆'"
                    >
                      <elCircle
                        :pixiMap="pixiMap"
                        :featureDatas="circleDatas"
                        @entryInput="entryInput"
                      ></elCircle>
                    </div>
                    <div
                      v-else-if="geometryArr[geometryArrIndex].sType == '文本'"
                    >
                      <elText
                        :pixiMap="pixiMap"
                        :featureDatas="textDatas"
                        @entryInput="entryInput"
                      ></elText>
                    </div>
                    <div
                      v-else-if="geometryArr[geometryArrIndex].sType == '面'"
                    >
                      <LTHatch
                        :pixiMap="pixiMap"
                        :featureDatas="LTHatchDatas"
                        @entryInput="entryInput"
                      ></LTHatch>
                    </div>
                    <div
                      v-else-if="geometryArr[geometryArrIndex].sType == '标注'"
                    >
                      <LTDimension
                        :pixiMap="pixiMap"
                        :featureDatas="LTDimensionDatas"
                        @entryInput="entryInput"
                        @setArrowValue="setArrowValue"
                      ></LTDimension>
                    </div>
                    <div
                      v-else-if="geometryArr[geometryArrIndex].sType == '椭圆'"
                    >
                      <LTEllipse
                        :pixiMap="pixiMap"
                        :featureDatas="LTEllipseDatas"
                        @entryInput="entryInput"
                      ></LTEllipse>
                    </div>
                    <div
                      v-else-if="geometryArr[geometryArrIndex].sType == '弧'"
                    >
                      <LTArc
                        :pixiMap="pixiMap"
                        :featureDatas="LTArcDatas"
                        @entryInput="entryInput"
                      ></LTArc>
                    </div>
                    <div
                      v-else-if="
                        geometryArr[geometryArrIndex].sType == '椭圆弧'
                      "
                    >
                      <REllipse
                        :pixiMap="pixiMap"
                        :featureDatas="REllipseDatas"
                        @entryInput="entryInput"
                      ></REllipse>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div
              v-else
              class="box-card-content"
              style="
                text-align: center;
                padding: 24px 0;
                font-size: 14px;
                color: #666;
              "
            >
              {{ isShowLayer ? "暂无图层" : "暂无属性" }}
            </div>
          </div>
          <!-- 新增、编辑快 -->
          <el-dialog
            :title="isAddBlock ? '创建块' : '插入块'"
            :visible.sync="showDialog"
            width="680px"
            :append-to-body="true"
            :close-on-click-modal="false"
            @close="cancelDialog('showDialog')"
            class="custem-dialog"
            v-dialogDrag
            v-disable-contextmenu
          >
            <div class="dialog-body-box" v-closeSelect>
              <div class="block-wrapper" v-stopdrag>
                <div classs="block-title" style="padding-bottom: 8px">
                  名称(N):
                </div>
                <el-input
                  v-if="isAddBlock"
                  v-model="blockForm.blockName"
                  placeholder="请输入名称"
                ></el-input>
                <el-select
                  v-else
                  ref="refK"
                  v-model="blockForm.blockName"
                  style="margin-right: 10px; margin-bottom: 0px; width: 100%"
                  filterable
                  placeholder="请选择"
                  @change="changeBlock"
                >
                  <el-option
                    v-for="item in blockList"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  />
                </el-select>
                <template v-if="!isAddBlock">
                  <div
                    class="flex align-start text-item spray-input-box"
                    style="padding: 16px 0 0 0"
                    v-for="(item, index) in blockattriList"
                    :key="index"
                  >
                    <div classs="block-title" style="width: 100px">
                      {{ item.text }} :
                    </div>
                    <div class="spray-input">
                      <el-input v-model="item.context"> </el-input>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div slot="footer" class="flex-center block-footer">
              <el-button
                @click="cancelDialog('showDialog')"
                size="nomal"
                native-type="button"
                >取消</el-button
              >
              <el-button @click="doSubmit" type="primary" size="nomal"
                >确定</el-button
              >
            </div>
          </el-dialog>
          <!-- 文本设置 -->
          <el-dialog
            title="文本设置"
            :visible.sync="showText"
            width="680px"
            :append-to-body="true"
            :close-on-click-modal="false"
            @close="cancelDialog('showText')"
            class="custem-dialog"
            v-dialogDrag
            v-disable-contextmenu
          >
            <div class="dialog-body-box text-container" v-closeSelect>
              <div class="block-wrapper" v-stopdrag>
                <div class="flex align-start text-item">
                  <div classs="block-title" style="width: 80px">文本内容:</div>
                  <el-input
                    v-model="textForm.content"
                    placeholder="请输入文本内容"
                    type="textarea"
                    resize="none"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                  ></el-input>
                </div>
                <div class="flex align-center text-item">
                  <div classs="block-title" style="width: 80px">字体大小:</div>
                  <el-input
                    v-model="textForm.fontSize"
                    placeholder="请输入字体大小"
                    type="number"
                  >
                  </el-input>
                  <span style="margin-left: 8px">mm</span>
                </div>
                <div class="flex align-center text-item">
                  <div classs="block-title" style="width: 80px">字体颜色:</div>
                  <div style="flex:1;">
                    <selectColor
                      v-model="textForm.colorIndex"
                      :color="textForm.color"
                      @callBackColor="callBackTxtColor"
                      refName="ztys"
                      ref="ztys"
                    ></selectColor>
                  </div>
                </div>
                <div class="flex align-center text-item">
                  <div classs="block-title" style="width: 80px">旋转角度:</div>
                  <el-input
                    v-model="textForm.angle"
                    placeholder="请输入旋转角度"
                  ></el-input>
                  <span style="margin-left: 8px">°</span>
                </div>
              </div>
            </div>
            <div slot="footer" class="flex-center block-footer">
              <el-button
                @click="cancelDialog('showText')"
                size="nomal"
                native-type="button"
                >取消</el-button
              >
              <el-button @click="doSubmitText" type="primary" size="nomal"
                >确定</el-button
              >
            </div>
          </el-dialog>

          <!-- 管径和喷头数量关系 -->
          <el-dialog
            title="管径和喷头数量关系"
            :visible.sync="showRelation"
            :append-to-body="true"
            :close-on-click-modal="false"
            @close="cancelRelation"
            class="custem-dialog"
            v-dialogDrag
            v-disable-contextmenu
          >
            <div
              class="dialog-body-box"
              style="max-height: 305px; min-height: 305px"
            >
              <div class="cad-select_wrapper scroll-Y" v-stopdrag>
                <div class="block-wrapper">
                  <div class="flex">
                    <el-button size="small" @click="addRelationShip"
                      >新增</el-button
                    >
                  </div>
                  <div class="relation-wrapper">
                    <div class="flex relation-item-box">
                      <div class="flex relation-value-box">
                        <div class="relation-item">管径</div>
                        <div class="relation-item">控制最大喷头数N</div>
                      </div>
                      <div class="flex relation-item-del">操作</div>
                    </div>
                    <template v-if="relationshipList.length > 0">
                      <div
                        class="flex relation-item-box"
                        v-for="(it, idx) in relationshipList"
                        :key="it.id"
                      >
                        <div class="flex relation-value-box">
                          <div class="relation-item">
                            <el-input v-model="it.radius"></el-input>
                          </div>
                          <div class="relation-item">
                            <el-input
                              v-model="it.maxVal"
                              type="textarea"
                              :rows="1"
                              :autosize="true"
                              resize="none"
                              v-stopdrag
                            ></el-input>
                          </div>
                        </div>
                        <div class="flex relation-item-del">
                          <el-button
                            size="small"
                            @click="delRelationShip(idx)"
                            type="danger"
                            >删除</el-button
                          >
                        </div>
                      </div>
                    </template>
                  </div>
                  <div class="noData" v-if="relationshipList.length == 0">
                    暂无数据
                  </div>
                </div>
              </div>
            </div>
            <div slot="footer" class="flex-center block-footer">
              <el-button
                @click="cancelRelation"
                size="nomal"
                native-type="button"
                >取消</el-button
              >
              <el-button @click="sureRelation" type="primary" size="nomal"
                >确定</el-button
              >
            </div>
          </el-dialog>
          <!-- 底部栏目 -->
          <div
            class="bLm-container"
            :class="{ 'cad-right-box': cardList.length > 0 }"
            :style="{ left: !isShowEdit ? '2px' : '37px' }"
            v-show="isShowBLm"
          >
            <div class="flex bLm-box">
              <!-- <div
                class="flex bLm-icon-box"
                @click="isShowBLm = false"
              >
                <i class="el-icon-close"></i>
              </div> -->
              <div class="flex bLm-icon-box">
                <svg-icon icon-class="code" />
              </div>

              <div class="flex" v-stopdrag style="flex: 1">
                <div class="flex bLm-before" v-if="bLmData.bfTxt">
                  {{ bLmData.bfTxt }}
                </div>
                <div
                  class="flex bLm-btn-box"
                  v-if="bLmData.btnList && bLmData.btnList.length > 0"
                >
                  [
                  <div
                    class="flex bLm-btn"
                    v-for="btn in bLmData.btnList"
                    :key="btn"
                    @click="clickBlmBtm(btn)"
                  >
                    {{ btn }}
                  </div>
                  ]
                </div>
                <div class="flex bLm-after" v-if="bLmData.afTxt">
                  {{ bLmData.afTxt }}
                </div>

                <div class="bLm-input-box">
                  <el-input
                    v-model="bLmData.value"
                    ref="plsj"
                    @keyup.enter.native="sureBlm"
                  ></el-input>
                </div>
              </div>
              <div
                class="statusbar-container"
                :class="{ 'cad-right-box': cardList.length > 0 }"
                :style="{ left: !isShowEdit ? '8px' : '40px' }"
              >
                <div class="statusbar-wrapper">
                  <div class="flex starusbar-item-box">
                    <div
                      class="starusbar-item"
                      v-for="item in statusbarData"
                      :key="item.id"
                      @click="selectStatus(item)"
                      :class="{ 'starusbar-item-active': item.isSelect }"
                    >
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 设置 -->
          <el-dialog
            title="设置"
            :visible.sync="showSetting"
            :append-to-body="true"
            :close-on-click-modal="false"
            @close="showSetting = false"
            class="custem-dialog"
            width="600px"
            v-dialogDrag
            v-disable-contextmenu
          >
            <div
              class="dialog-body-box text-container  setting-container"
              v-closeSelect
            >
              <div class="flex scroll-Y  cad-select_wrapper" v-closeSelect>
                <!-- <div class="setting-left-box">setting-left-box</div>
              <div class="setting-right-box">setting-right-box</div> -->
                <el-tabs
                  :tab-position="tabPosition"
                  style="height: 325px; width: 100%"
                  v-model="settingVal"
                >
                  <el-tab-pane label="基础设置" name="基础设置">
                    <div lass="scroll-Y setting-tab-box">
                      线形比例因子:
                    </div>
                    <div class="scroll-Y setting-tab-box">
                      <el-select
                        ref="refXx"
                        placeholder="选择比例因子"
                        v-model="dwgSettings.scaleVal"
                        style="margin-left:0px;"
                        @change="changeScale"
                      >
                        <el-option
                          v-for="(item, idx) in dwgSettings.scales"
                          :key="idx"
                          :label="item.value"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                      <div
                        v-if="dwgSettings.scaleVal == '自定义'"
                        class="flex align-start text-item spray-input-box"
                        style="padding: 16px 0 0 0;width:207px;"
                      >
                        <div classs="block-title" style="width: 95px">
                          线形比例因子:
                        </div>
                        <div class="spray-input">
                          <el-input v-model="dwgSettings.scale"> </el-input>
                        </div>
                      </div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="极轴设置" name="极轴设置">
                    <div class="scroll-Y setting-tab-box">
                      <div class="polar-axis-box">
                        <div class="polar-axis-title">
                          <el-checkbox v-model="polarAxis.isOpen"
                            >启动极轴追踪(P)</el-checkbox
                          >
                        </div>
                        <div class="flex polar-axis-wrapper">
                          <div class="polar-axis-left-box">
                            <div class="polar-axis-title-box">极轴角设置</div>
                            <div class="polar-axis-item">
                              <div class="polar-axis-item-title">
                                增角量(I):
                              </div>
                              <div class="polar-axis-item-content">
                                <el-select
                                  v-model="polarAxis.angle"
                                  placeholder="请选择增角量"
                                  ref="refZjl"
                                >
                                  <el-option
                                    v-for="(item, idx) in polarAxis.angleList"
                                    :key="idx"
                                    :label="item"
                                    :value="item"
                                  >
                                  </el-option>
                                </el-select>
                              </div>
                            </div>
                            <div class="polar-axis-item">
                              <div class="polar-axis-item-title">
                                <el-checkbox v-model="polarAxis.isAdd"
                                  >附加角(D)</el-checkbox
                                >
                              </div>
                              <div
                                class="flex polar-axis-item-content polar-axis-add"
                              >
                                <div
                                  class="flex scroll-Y polar-axis-content-item"
                                >
                                  <template
                                    v-if="polarAxis.angleValList.length > 0"
                                  >
                                    <div
                                      class="flex polar-axis-value"
                                      :class="{
                                        'polar-axis-active': delIndex == index
                                      }"
                                      style="height:32px;"
                                      v-for="(item,
                                      index) in polarAxis.angleValList"
                                      :key="index"
                                      @click.stop="selectDelPolar(index)"
                                    >
                                      {{ item }}
                                    </div>
                                  </template>
                                  <div
                                    class="flex polar-axis-value"
                                    v-show="polarAxis.isAdd"
                                  >
                                    <!-- @keyup.enter.native="sureAngle" -->
                                    <el-input
                                      placeholder="请输入角度"
                                      v-model="polarAxis.addVal"
                                      @input="changeAngle"
                                      @blur="sureAngle"
                                    >
                                    </el-input>
                                  </div>
                                </div>
                                <div class="flex polar-axis-btn-box">
                                  <el-button @click="addAngle" size="mini"
                                    >新建(N)</el-button
                                  >
                                  <el-button
                                    @click="delAngle"
                                    size="mini"
                                    :disabled="delIndex == -1"
                                    >删除</el-button
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="flex polar-axis-right-box">
                            <div class="polar-axis-right-item">
                              <div class="polar-axis-title-box">
                                对象捕捉追踪设置
                              </div>

                              <el-checkbox
                                style="margin-top: 22px"
                                v-model="polarAxis.objectSnap"
                                >特定角度追踪</el-checkbox
                              >
                            </div>
                            <div class="polar-axis-right-item">
                              <div class="polar-axis-title-box">极轴角测量</div>
                              <el-radio-group v-model="polarAxis.angleMeasure">
                                <el-radio :label="false">绝对(A)</el-radio>
                                <el-radio :label="true">相对上一段(R)</el-radio>
                              </el-radio-group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
            <div class="flex-center block-footer" slot="footer">
              <el-button
                @click="showSetting = false"
                size="nomal"
                native-type="button"
                >取消</el-button
              >
              <el-button @click="sureSetting" type="primary" size="nomal"
                >确定</el-button
              >
            </div>
          </el-dialog>
        </template>
        <div
          class="map"
          id="map"
          ref="page_map"
          :style="{
            left: !isShowEdit ? '0px' : '36px',
            right: cardList.length > 0 ? '300px' : '36px'
          }"
          v-if="isLoading"
        >
          <div ref="page_canvas" class="canvas" tabindex="0">
            <!-- 右击事件 -->
          </div>
          <div
            class="right-click-box"
            v-if="isShowMouseBox"
            :style="{ top: mouseTop, left: mouseLeft }"
          >
            <div class="right-click-item-box">
              <div
                class="right-click-item"
                v-for="(right, idx) in rightClickList"
                :key="idx"
                @click="mouseRightClick(right.fn)"
              >
                {{ right.name }}
              </div>
            </div>
          </div>
        </div>
        <line-vue ref="lineVue" :supThis="sup_this"></line-vue>
        <order-vue ref="orderVue" :supThis="sup_this"></order-vue>
        <spray-vue ref="sprayVue" :supThis="sup_this" />
        <schematicdiagram ref="schematicdiagram" :supThis="sup_this">
        </schematicdiagram>

        <printPDFVue ref="printPDFVue" :supThis="sup_this"> </printPDFVue>
      </div>
    </div>
    <div class="mask-wrapper" v-if="showSpray"></div>
    <!--  -->
    <div class="mask-fixed-box" v-show="mapLoading">
      <div class="mask-fixed">
        <el-progress
          type="dashboard"
          :percentage="percentage"
          :color="percentageColors"
        ></el-progress>
        <div class="mask-fixed-title">单桌派平台,{{ percentageText }}</div>
      </div>
    </div>
    <dialogColor
      ref="dialogColor"
      @callBackColor="callBackDialogColor"
    ></dialogColor>
    <!-- 快速选择 -->
    <ksxzItem v-if="pixiMap" ref="ksxzItem" :pixiMap="pixiMap"></ksxzItem>
  </div>
</template>
<script>
import { get, post } from "@/api/index";
import { toolMixin } from "@/utils/toolMixin";
import { CadMap } from "@/cadplugins/map/cadmap";
import { SaveCommandImpl } from "@/cadplugins/tools/savecommands";
import { CommandFactory } from "@/cadplugins/map/commandfactory";
import { SprinklerReactor } from "@/business/sprinkler/sprinklerreactor";
import { SprinkerRegisterCommand } from "@/business/sprinkler/sprinkerregistercommand";
import { CustomCommandManager } from "@/business/sprinkler/customcommandmanager";
import userInfo from "@/components/common/userInfo";
import cadItem from "@/components/cadItem";
import lineVue from "@/components/cadComponents/linestyle";
import orderVue from "@/components/cadComponents/order"; //自定义命令
import sprayVue from "@/components/cadComponents/spray"; //喷淋配置
import printPDFVue from "@/components/cadComponents/printpdf"; //打印PDF
import selectColor from "@/components/cadComponents/selectColor"; //颜色选择
import { MainApp } from "@/cadplugins/mainapp"

import schematicdiagram from "@/components/cadComponents/schematicdiagram.vue";
import { getToken } from "@/utils/auth";
import axios from "axios";

// 属性组件
const dyxLine = () => import("./element/dyxLine.vue"); //多义线-验证完成
const elBlock = () => import("./element/elBlock.vue"); //块-验证完成
const elCircle = () => import("./element/elCircle.vue"); //圆-验证完成
const elText = () => import("./element/elText.vue"); //文本-验证完成
const LTHatch = () => import("./element/LTHatch.vue"); //面-验证完成
const LTDimension = () => import("./element/LTDimension.vue"); //标注-验证完成
const LTEllipse = () => import("./element/LTEllipse.vue"); //椭圆-验证完成
const LTArc = () => import("./element/LTArc.vue"); //弧-验证完成
const REllipse = () => import("./element/REllipse.vue"); //椭圆弧-验证完成
const straightLine = () => import("./element/straightLine.vue"); //线-验证完成
const dialogColor = () => import("@/components/cadComponents/dialogColor.vue"); //颜色弹框
const ksxzItem = () => import("./element/ksxz.vue"); //快速选择
export default {
  name: "cad",
  mixins: [toolMixin],
  components: {
    userInfo,
    cadItem,
    lineVue,
    orderVue,
    sprayVue,
    schematicdiagram,
    printPDFVue,
    selectColor,
    dyxLine,
    elCircle,
    elBlock,
    elText,
    LTHatch,
    LTDimension,
    LTEllipse,
    LTArc,
    REllipse,
    straightLine,
    dialogColor,
    ksxzItem
  },
  computed: {
    colorLists() {
      return window.colorLists;
    },
    colorList() {
      return window.colorList;
    },
    useColors() {
      return window.colorLists.slice(1, 10);
    },
    useColorss() {
      return window.colorLists.slice(
        window.colorLists.length - 5,
        window.colorLists.length
      );
    }
  },
  data() {
    return {
      mapLoading: false,
      mapId: "", //"b5910243","3b2adee2"
      isLoading: false,
      md5: "",
      productId: "",
      shareUrl: "",
      cadUrl: "/dwg/convert/public/",
      mapTimer: null,
      canvasWidth: 0,
      canvaslength: 0,
      // colorLists: window.colorLists,
      productName: "",
      sup_this: this,
      percentageText: "加载中...",
      percentage: 10,
      percentageColors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#6f7ad3", percentage: 100 }
      ]
    };
  },
  created() {
    const resourceList = ["fonts/正等线体.fnt"];
    window.PIXI.Assets.load(resourceList).then(() => {});
    SprinkerRegisterCommand.init();
  },
  activated() {
    this.isOnShowEdit = getToken();
    this.percentageText = "加载中...";
    this.percentage = 0;
    //注意是等待字体文件加载完成之后再加载地图;
    this.initCadMap();
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onEsc);
  },
  methods: {
    changeMapSize() {
      const page_map = this.$refs.page_map;
      this.canvasWidth = page_map.clientWidth;
      let canvasWidth = document.body.clientWidth;
      if (this.isShowEdit) {
        canvasWidth = canvasWidth - 36;
      }
      if (this.cardList.length > 0) {
        canvasWidth = canvasWidth - 300;
      } else {
        canvasWidth = canvasWidth - 36;
      }
      this.canvasWidth = canvasWidth;
      this.canvaslength = page_map.clientHeight;
      this.pixiMap.resize(this.canvasWidth, this.canvaslength);
    },
    
    initCadMap() {
      MainApp.getInstance().init(()=>{
        this.$nextTick(() => {
            if (this.isStaging && getToken()) {
              this.cadUrl = "/dwg/convert/";
            }
            this.md5 = this.$route.query.md5 || "";
            this.productName = this.$route.query.productName || "";
            this.productId = this.$route.query.productId || "";
            if (this.md5 && this.productId) {
              this.resetData();
              this.mapTimer = null;
              this.isLoading = true;
              this.mapLoading = true;
              this.mapId = "";
              document.addEventListener("keydown", this.onEsc);
              this.$nextTick(() => {
                this.showBlm();
              });
              this.shareUrl = `https://dendralink.com/#/share?md5=${this.md5}&productId=${this.productId}&productName=${this.productName}`;
              this.resetMap(this.md5);
            } else this.$router.go(-1);
          });

      });
        // .then(res => {
          
        // })
        // .catch(err => {
        //   this.loadFont();
        // });
    },
    changePercentage(val) {
      if (val && val.close && val.close == true) {
        this.mapLoading = false;
      } else {
        let vales = Math.ceil((val.current / val.count) * 100);
        if (vales <= 100) {
          this.$set(
            this,
            "percentage",
            Math.ceil((val.current / val.count) * 100)
          );
          this.percentageText = val.text;
        }
      }
      this.$forceUpdate();
    },
    onresize() {
      if (this.pixiMap) {
      }
    },

    resetMap(md5) {
      if (md5) {
        get(this.cadUrl + md5)
          .then(res => {
            if (res.code == 200 && res.data && res.data.mapId) {
              this.mapId = res.data.mapId;
              this.$nextTick(() => {
                this.initData();
              });
              setTimeout(() => {
                this.sendMap(this.mapId);
              }, 500);
            }
          })
          .catch(() => {
            this.$router.go(-1);
          });
      } else {
        this.initData();
      }
    },
    addDesign(res) {
      let json = JSON.parse(res);
      this.pixiMap.dwgservice.addDesign(json);
      //绘制块
      let block = json["block"]["blockname"];
      let param = { blockName: block };
      CommandFactory.getInstance().execYieldCommand(
        "insert",
        this.pixiMap,
        param
      );
    },
    sendMap() {
      window.loadMapEnd = this.loadMapEnd;
      window.showBlm = this.showBlm;
      window.downDesgFile = this.downDesgFile;
      window.downLoadPdf = this.downLoadPdf;
      window.getProjectData = this.getProjectData;
      window.CustomCommandManager = CustomCommandManager.getInstance();
      window.saveProjectData = this.saveProjectData;
      window.getUserSettingData = this.getUserSettingData;
      window.saveUserSettingData = this.saveUserSettingData;
      window.showMouse = this.showMouse;
      window.onGbMouse = this.onGbMouse;
      if (this.mapId) this.loadMap(this.mapId);
    },
    // 加载地图
    loadMap(map_id) {
      this.pixiMap.clearLayers();
      //  map_id="1cef563c";
      this.pixiMap.setMapID(
        map_id,
        window.configData.VUE_APP_BASEURL + "/dwg/",
        getToken(),
        this.productId,
        this.changePercentage
      );
      let reactor = new SprinklerReactor(this.pixiMap);
      this.pixiMap.addReactor(reactor);
      //生成环境不自动保存;
      if (process.env.NODE_ENV !== "development") {
        if (!this.mapTimer) {
          setInterval(() => {
            this.save(false);
          }, 10 * 60 * 1000);
        }
      }
    },
    initSetting() {
      //捕捉设置;
      this.getUserSettingData().then(res => {
        if (res && res.polaraxis) {
          this.pixiMap.snap.fromJson(res.polaraxis);
          if (this.statusbarData.length > 1) {
            //捕捉开关;
            this.statusbarData[0].isSelect = this.pixiMap.snap.snapEnable;
            //正交;
            this.statusbarData[1].isSelect = this.pixiMap.snap.orthogonalEnable;
          }
        }
      });
      //dwg系统设置;
      this.getUserSettingData().then(res => {
        if (res && res.dwgsetting) {
          this.pixiMap.dwgservice.dwgScale = res.dwgsetting.scale;
        }
      });
      //自定义命令初始化;
      this.getUserSettingData().then(res => {
        if (res && res.customcommand) {
          CustomCommandManager.getInstance().commandList = JSON.parse(
            res.customcommand
          );
        }
      });
    },

    loadMapEnd() {
      //加载用户配置信息;
      this.initSetting();
    },
    initData() {
      //rendermode: true 为纯渲染  false 为可编辑
      let isEditCad =
        this.getPackageSimpleByVal("feaCadOnlineEdit") && getToken()
          ? true
          : false;

      const page_canvas = this.$refs.page_canvas;

      this.pixiMap = new CadMap({
        canvas: page_canvas,
        rendermode: !isEditCad
      });
      this.changeMapSize();
      CustomCommandManager.getInstance().initCommand();
      //刚初始化清除啥
      // this.pixiMap.clearLayers();

      page_canvas.addEventListener("contextmenu", function(e) {
        e.preventDefault();
      });
    },
    alignDimension() {
      CommandFactory.getInstance().execCommand("drawDimension", this.pixiMap, {
        rotate: true
      });
    },
    rotateDimension() {
      CommandFactory.getInstance().execCommand("drawDimension", this.pixiMap, {
        rotate: false
      });
    },
    angularDimension() {
      CommandFactory.getInstance().execCommand(
        "drawAngular2LDimension",
        this.pixiMap
      );
    },
    delete() {
      CommandFactory.getInstance().execCommand("$delete", this.pixiMap);
    },
    qselect() {
      this.showSelection();
    },
    drawPix(selectItem, isShow = false) {
      if (!isShow) this.navigationIndex = -1;
      //文本
      if (selectItem.type == "text") {
        this.onShowText();
        return;
      }
      if (this.isFunction(this[selectItem.fn])) {
        this[selectItem.fn](selectItem);
        this.navigationIndex = -1;
        return;
      } else if (this.isString(selectItem.fn)) {
        CommandFactory.getInstance().execCommand(selectItem.fn, this.pixiMap);
        this.navigationIndex = -1;
      }
    },
    drawPixTool(item, idx) {
      if (this.navigationIndex == idx) {
        this.navigationIndex = -1;
        return;
      }
      this.navigationIndex = -1;
      if (item.list && item.list.length > 0) {
        this.navigationIndex = idx;
      } else this.drawPix(item);
    },
    save(update_image) {
      if (!this.pixiMap) {
        return;
      }
      SaveCommandImpl.save(this.pixiMap, update_image);
    },
    drawSprinkler() {
      CommandFactory.getInstance().execCommand("drawsprinkler", this.pixiMap);
    },
    designSprinkler() {
      this.$refs.schematicdiagram.init(this.pixiMap, this.productId);
    },
    exportPDF() {
      this.$refs.printPDFVue.init(this.pixiMap);
    },
    countSprinkler() {
      CommandFactory.getInstance().execCommand("sparycalculate", this.pixiMap);
    },
    // 绘画矩阵
    hatch() {
      CommandFactory.getInstance().execCommand("addhatch", this.pixiMap);
    },
    // 编辑-矩阵
    arrayrect() {
      CommandFactory.getInstance().execCommand("arrayRect", this.pixiMap);
    },
    regen() {
      this.pixiMap.regen();
      //CommandFactory.getInstance().execCommand("select1", this.pixiMap);
    },
    redo() {
      let fun = JSON.stringify({ commandName: "redo", paramList: ["1"] });
      CommandFactory.getInstance().execCommand(fun, this.pixiMap);
    },
    undo() {
      let fun = JSON.stringify({ commandName: "undo", paramList: ["1"] });
      CommandFactory.getInstance().execCommand(fun, this.pixiMap);
    },
    deleteFeature() {
      CommandFactory.getInstance().execCommand("$delete", this.pixiMap);
    },
    // 居中
    fullmap() {
      this.pixiMap.fullMap();
    },
    goBack() {
      this.$router.go(-1);
    },
    onShowBlock() {
      this.onSetBlock();
    },
    downDesgFile(datas) {
      let data = datas && typeof datas == "string" ? JSON.parse(datas) : "";

      if (data && data.pipe_segments) {
        axios({
          url:
            window.configData.VUE_APP_BASEURL + "/cad/project/spray/download", // 请求地址
          method: "post",
          headers: {
            Authorization: "Bearer " + getToken()
          },
          responseType: "blob", // 表明返回服务器返回的数据类型
          data: data
        })
          .then(res => {
            if (res.data) {
              let url = window.URL.createObjectURL(res.data);
              let link = document.createElement("a");
              link.style.display = "none";
              link.href = url;
              let contentDisposition = res.headers["content-disposition"];
              let filename = contentDisposition
                .split(";")[1]
                .trim()
                .split("=")[1]
                .replace(/"/g, "");
              link.setAttribute("download", encodeURIComponent(filename)); //避免中文名的问题，服务端对文件名编码过，需要进行解码获
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch(() => {});
      }
    },
    // 下载-pdf
    downLoadPdf(it_json, is_zip) {
      if (!this.getPackageSimpleByVal("feaDwgPdfDownload")) {
        return;
      }
      const _this = this;
      if (!it_json) {
        return;
      }

      function blobToString(blob, callback) {
        let reader = new FileReader();
        reader.onload = function() {
          callback(reader.result);
        };
        reader.readAsText(blob);
      }

      let data = {
        projectId: this.productId,
        json: it_json,
        width: 2000,
        height: 2000
      };
      let pdf_type = is_zip ? "pdf/zip" : "pdf";
      let pdf_url =
        window.configData.VUE_APP_BASEURL + `/cad/project/download/${pdf_type}`;
      axios({
        url: pdf_url, // 请求地址
        method: "post",
        data,
        headers: {
          Authorization: "Bearer " + getToken()
        },
        responseType: "blob" // 表明返回服务器返回的数据类型
      })
        .then(res => {
          if (res.data) {
            let url = window.URL.createObjectURL(res.data);
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            // link.setAttribute("download", it.file.fileName);
            let contentDisposition = res.headers["content-disposition"];
            let filename = contentDisposition
              .split(";")[1]
              .trim()
              .split("=")[1]
              .replace(/"/g, "");
            link.setAttribute("download", decodeURIComponent(filename));
            document.body.appendChild(link);
            link.click();
            if (_this.getPackageSimpleByVal("feaDwgPdfDownload")) {
              _this.cancelDown();
            }
          }
        })
        .catch(err => {
          blobToString(err.response.data, str => {
            let json = JSON.parse(str);
            this.$message.error(json.message);
          });
        });
    },

    //获取用户配置信息;
    getUserSettingData() {
      return new Promise((resolve, reject) => {
        get(this.$setApi("/cus/user/config/"))
          .then(res => {
            let data = {};
            if (res && res.data && typeof res.data == "string") {
              data = JSON.parse(res.data);
              if (typeof data == "string") data = JSON.parse(data);
            }
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    saveUserSettingData(type, data = {}) {
      return new Promise((resolve, reject) => {
        this.getUserSettingData()
          .then(res => {
            let postData = res;
            postData[type] = data;
            post(this.$setApi("/cus/user/config/"), postData).then(res => {
              if (res && res.data) {
                resolve();
              }
            });
          })
          .catch(() => {
            reject();
          });
      });
    }
  },

  destroyed() {
    this.$store.dispatch("user/setStaging", false);
    this.isLoading = false;
    this.pixiMap.clearLayers();
    this.pixiMap = null;
    this.mapId = "";
    if (this.mapTimer) {
      clearInterval(this.mapTimer);
      this.mapTimer = null;
    }
  },
  deactivated() {
    this.$store.dispatch("user/setStaging", false);
    this.isLoading = false;
    if (this.pixiMap !== null) {
      this.pixiMap.clearLayers();
      this.pixiMap = null;
    }

    this.mapId = "";
    if (this.mapTimer) {
      clearInterval(this.mapTimer);
      this.mapTimer = null;
    }
  },

  watch: {}
};
</script>
<style lang="stylus" scoped>
@import '~@/assets/styl/cad'
.cad-main-container
  background #42454a
.home-top-wrapper
  background #52545a !important

.cad-btn-box
  height 46px
  align-items center
  justify-content space-between
  background #42454a

  >>>.el-button--primary
    color #D0D0D1 !important
    background #42454a !important
    border-color #9c9da0 !important

.cad-img
  width 100%

.cad-container
  overflow hidden
  user-select none

  .back-box
    justify-content flex-end
    align-items center

.cad-title
  margin-left 10px
  font-size 16px

.back-svg
  margin-left 28px
  cursor pointer
</style>
